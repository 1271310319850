<template>
  <div class="has-nav-bar user-face-comparison">
    <van-nav-bar fixed left-arrow title="人脸比对" @click-left="onClickLeft" />
    <div class="user-face-content">
      <div class="user-face-txt">
        人脸识别功能验证你的身份信息， 请确保为
        <span class="txt-name">{{ $store.state.login.userInfo.nickName }}</span>
        本人操作
      </div>
      <div class="user-face-img">
        <img src="../../assets/images/userFace/img.png" alt="" />
      </div>
      <div class="user-face-tip">请将面部对准屏幕，平视镜头，保证面部清晰</div>
      <div class="user-face-log">
        <div class="user-face-log-box">
          <div class="user-face-log-box-img">
            <img src="../../assets/images/userFace/ipone.svg" alt="" />
          </div>
          <div class="user-face-log-box-txt">正对手机</div>
        </div>
        <div class="user-face-log-box">
          <div class="user-face-log-box-img">
            <img src="../../assets/images/userFace/guang.svg" alt="" />
          </div>
          <div class="user-face-log-box-txt">光线充足</div>
        </div>
        <div class="user-face-log-box">
          <div class="user-face-log-box-img">
            <img src="../../assets/images/userFace/face.svg" alt="" />
          </div>
          <div class="user-face-log-box-txt">脸无遮拦</div>
        </div>
      </div>
      <div class="user-face-btn" @click="onTakePhoto">去拍照</div>
      <van-dialog
        v-model="show"
        title=""
        :show-cancel-button="dialogState === 2"
        class-name="user-face-dialog"
        :confirm-button-text="switchStatus(dialogState)"
        @confirm="Retake"
      >
        <div class="dialog-content">
          <van-icon
            name="cross"
            class="dialog-content-icon"
            color="#9496A3"
            size="18"
            @click="closeDialog"
          />
          <img
            :src="switchImg(dialogState)"
            alt=""
            class="dialog-content-img"
          />
          <div class="dialog-content-txt">{{ switchTxt(dialogState) }}</div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";
import { mapState } from "vuex";
import { loading } from "@/utils/constant";

export default {
  name: "UserFaceComparison",
  components: {
    [NavBar.name]: NavBar
  },
  computed: {
    ...mapState({})
  },
  data() {
    return {
      loading: { ...loading },
      show: false,
      dialogState: 0 //1验证通过 2验证失败 3资料不全
    };
  },
  created() {},
  methods: {
    // 拍照执行人脸对比
    onTakePhoto() {
      if (!this.$route.params.paramsData) {
        this.show = true;
        this.dialogState = 3;
        return;
      }
      window.compareFaceCB = cbV => {
        // 返回true是对比成功 false 是对比失败
        if (cbV === "true") {
          this.dialogState = 1;
        } else {
          this.dialogState = 2;
        }
        this.show = true;
        // alert(cbV);
        // TODO 这里执行后续逻辑
      };
      const otherParamsObj = [
        {
          key: "bizModule", // 业务模块编码
          value: "1001" // 业务模块编码值  1100 作业，1500 培训，1400  巡检， 1001 个人中心
        }
      ];
      const otherParams = JSON.stringify(otherParamsObj);
      window.androidH5.takePhotoFace("compareFaceCB", otherParams);
    },
    onClickLeft() {
      this.$router.back();
    },
    closeDialog() {
      this.show = false;
    },
    // 点击重拍
    Retake() {
      if (this.dialogState === 2) {
        this.onTakePhoto();
      } else if (this.dialogState === 1) {
        // 验证通过, 跳转到个人中心
        this.onClickLeft();
      }
    },
    switchStatus(status) {
      switch (status) {
        case 1:
          return "完成";
        case 2:
          return "重拍";
        case 3:
          return "关闭";
      }
    },
    switchTxt(status) {
      switch (status) {
        case 1:
          return "人脸验证通过";
        case 2:
          return "人脸验证失败";
        case 3:
          return "人员档案资料不完整";
      }
    },
    switchImg(status) {
      switch (status) {
        case 1:
          return require("../../assets/images/userFace/success.svg");
        case 2:
        case 3:
          return require("../../assets/images/userFace/fail.svg");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.user-face-comparison {
  .user-face-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .user-face-txt {
      margin: 7.8vh 60px 3vh;
      font-size: 16px;
      color: #2e2e4d;
      line-height: 26px;
      text-align: center;
      .txt-name {
        font-weight: 500;
      }
    }
    .user-face-img {
      width: 29vh;
      height: 29vh;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .user-face-tip {
      margin: 6.2vh 16px 4.2vh;
      font-size: 12px;
      color: #8c8f97;
    }
    .user-face-log {
      width: calc(100% - 80px);
      display: flex;
      justify-content: space-between;
      margin: 0 40px 8vh;
      .user-face-log-box {
        width: 60px;
        .user-face-log-box-img {
          width: 60px;
          height: 60px;
          margin-bottom: 16px;
        }
        .user-face-log-box-txt {
          width: 60px;
          font-size: 14px;
          color: #3b4664;
          text-align: center;
        }
      }
    }
    .user-face-btn {
      width: 343px;
      height: 44px;
      background: #1676ff;
      border-radius: 27px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
    }
    .user-face-dialog {
      .dialog-content {
        height: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .dialog-content-icon {
          position: absolute;
          top: 14px;
          right: 19px;
        }
        .dialog-content-img {
          width: 48px;
          height: 48px;
          background-size: 100% 100%;
          margin: 32px 0 16px;
        }
        .dialog-content-txt {
          font-size: 14px;
          font-weight: 500;
          color: #2e2e4d;
        }
      }
    }
  }
}
</style>
